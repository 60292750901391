.cust-modal-hide {
  display: none;
  position: fixed;
  z-index: 6;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.cust-modal-show {
  display: block;
  position: fixed;
  z-index: 6;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

/* Modal Content/Box */
.cust-modal-content {
  background-color: #fefefe;
  margin: 8% auto;
  padding: 0 20px;
  border: 1px solid #888;
  border-radius: 3px;
  width: 80%;
  animation-name: animatetop;
  animation-duration: 0.7s;
}

/* The Close Button */
.cust-modal-close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  padding-top: 4px;
}

.cust-modal-close:hover,
.cust-modal-close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.modal-bottom-close {
  text-align: center;
  padding: 20px 0;
}

.modal-bottom-close button {
  width: 150px;
  height: 40px;
  border: none;
  font-size: 0.8em;
  color: gray;
  margin-top: 8px;
  text-transform: uppercase;
}

.modal-bottom-close button:hover {
  background-color: rgb(224, 224, 224);
  cursor: pointer;
}

/* Add Animation */
@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }

  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes animateleave {
  from {
    top: 0;
    opacity: 1;
  }

  to {
    top: -300px;
    opacity: 0;
  }
}
