.login-wrapper {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  max-width: 300px;
  margin: auto;
  margin-top: 220px;
  padding: 40px;
  background: #c5c5c5;
  border-radius: 5px;
}

.logo-img-wrapper {
  max-width: 800px;
  margin-bottom: 20px;
}

.login-logo {
  width: 100%;
}

.submit-button {
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  background: #26a6db;
  border: none;
  border-radius: 3px;
  color: white;
}

.submit-button:hover {
  cursor: pointer;
}

input[type="password"] {
  padding: 6px;
  width: 94.6%;
}

.form-error {
  border: 1px solid #d44950;
  padding: 15px;
  background: #f8d7da;
  color: #d44950;
  margin-top: 10px;
  margin-bottom: 0;
  border-radius: 5px;
}

@media (max-width: 520px) {
  .login-wrapper {
    padding: 100px 20px;
  }
}
