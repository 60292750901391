.footer-wrapper {
  background-color: black;
  /* background: url("../../assets/images/FOOTER.jpg") no-repeat center center; */
  background-size: cover;
  color: white;
  border-top: 12px solid #C39F5C;
}

.footer-disc-link {
  color: #308db8;
}

.hr-fw {
  width: 100%;
}

.footer-logo-wrapper {
  max-width: 200px;
}

.footer-logo-wrapper img {
  width: 100%;
}

.footer-top-section {
  display: grid;
  grid-template-columns: auto auto;
  border-bottom: 1px solid rgb(136, 136, 136);
  padding-bottom: 20px;
  justify-content: start;
}

.footer-middle-section {
  margin: 20px 0;
}

.footer-middle-section p {
  font-size: 0.7em;
}

.footer-bottom-section {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgb(136, 136, 136);
  padding-top: 12px;
  font-size: 0.7em;
}

.footer-links {
  padding: 0 30px;
}

.footer-links-a {
  display: block;
  text-decoration: none;
  color: white;
  text-transform: uppercase;
  font-size: 0.7em;
  margin: 9px 0;
}

.footer-links-header {
  text-transform: uppercase;
  text-decoration: none;
  color: white;
  margin: 15px 0 20px;
  font-weight: 500;
  font-size: 0.8em;
}

.footer-links-header:hover,
.footer-links-a:hover {
  text-decoration: underline;
}

/* PP and TOU stuff */
.text-modal-wrapper {
  color: rgb(20, 20, 20);
  padding: 40px;
}

@media only screen and (max-width: 650px) {
  .text-modal-wrapper {
    padding: 40px 0;
  }
}
