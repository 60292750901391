.nav-wrapper {
  background: rgb(255, 255, 255);
  color: white;
  position: fixed;
  z-index: 5;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  box-shadow: 0 8px 6px -6px #777;
}

.nav-icon {
  margin-right: 30px;
}

.nav-button {
  display: inline;
}

.nav-button:hover {
  cursor: pointer;
}

.nav-dropdown-main-show {
  position: fixed;
  z-index: 4;
  width: 275px;
  top: 80px;
  transition: 0.5s;
  height: 100%;
}

/* .nav-dropdown-main-show {
  position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    visibility: hidden;
    background-color: #fff;
    background-clip: padding-box;
    outline: 0;
    transition: transform .3s ease-in-out;

    top: 0;
    left: 0;
    width: 400px;
    border-right: 1px solid rgba(0,0,0,.2);

    transform: none;

    visibility: visible;
} */

.nav-dropdown-main-hide {
  position: absolute;
  top: 80px;
  width: 275px;
  display: flex;
  z-index: 4;
  transition: 0.5s;
  margin-left: -290px;
  height: 100%;
}

/* .nav-dropdown-main-hide {
  transition: transform .3s ease-in-out;
  display: none;
  visibility: hidden;
  position: absolute;
  top: -100%;
  left: -100%;
} */

.nav-dropdown {
  background-color: white;
  padding: 20px;
  height: 100%;
  position: fixed;
}

.nav-ul {
  list-style-type: none;
  padding: 0;
}

.nav-ul-show li {
  padding: 12px 6px;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
}

.nav-ul-hide li {
  padding: 12px 6px;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
}

.nav-ul li {
  padding: 6px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}

.nav-ul li:hover {
  background-color: rgb(223, 223, 223);
}

.nav-ul li a {
  text-decoration: none;
  color: rgb(44, 44, 44);
}
.nav-ul li a:hover {
  cursor: pointer;
}

.menu-grid {
}

.menu-grid-column h3,
.menu-contact-link {
  margin-bottom: 4px;
  color: rgb(44, 44, 44);
  font-weight: 500;
}

.menu-contact-link {
  text-decoration: none;
  font-size: 1.17em;
}

/* HAMBURGER */
/* "Hide" checkbox -- moves it off screen*/
#menu-btn {
  position: absolute;
  top: -100%;
  left: -100%;
}
/* Hide hamburger for bigger screens */
.menu-icon {
  visibility: hidden;
}
.menu-btn {
  display: none;
}
.menu-icon {
  grid-area: hamburger;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  padding: 30px 20px 30px 0;
  position: relative;
  user-select: none;
  visibility: visible;
}
.navicon {
  background: black;
  display: block;
  height: 2px;
  width: 18px;
  position: relative;
}
.navicon:before {
  top: 5px;
}
.navicon:after {
  top: -5px;
}
.navicon:before,
.navicon:after {
  background: black;
  display: block;
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  transition: all 0.2s ease-out;
}
.menu-btn:checked ~ .menu {
  max-height: 240px;
}
.menu-btn:checked ~ .menu-icon .navicon {
  background: transparent;
}
.menu-btn:checked ~ .menu-icon .navicon:before {
  transform: rotate(-45deg);
}
.menu-btn:checked ~ .menu-icon .navicon:after {
  transform: rotate(45deg);
}
.menu-btn:checked ~ .menu-icon .navicon:before,
.menu-btn:checked ~ .menu-icon .navicon:after {
  top: 0;
}

@media only screen and (max-width: 450px) {
  .nav-icon {
    margin-right: 25%;
  }
}
