.hero-main-header {
  font-size: 2.5em;
}

.hero-container {
  position: relative;
}

.hero_content_container {
  /* padding: 0 40px; */
}

.video-overlay-banner {
  background: #000000;
  padding: 50px 160px;
  margin-top: 50px
}

.hmcLearnMoreButton {
  background-color: #c39f5c;
  border: none;
  padding: 8px 15px;
  color: white;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  letter-spacing: 1px;
  border-radius: 20px;
}

.hmcLearnMoreButton:hover,
.hmcBannerCloseButton:hover {
  cursor: pointer;
}

.hmcBannerCloseButton:hover {
  color: #fff;
  background-color: #970b29;
  border-color: #8e0a26;
  olor: #b20d30;
}

.hmcLearnMoreButton:hover {
  cursor: pointer;
}

.hmcBannerCloseButton {
  color: #fff;
  background-color: #b20d30;
  border-color: #b20d30;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  border: 1px solid rgba(0, 0, 0, 0);
}

#hero-video {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 85vh;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}

.hero-text-overlay {
  min-height: 85vh;
  display: flex;
  justify-content: center;
  color: white;
  align-content: space-between;
  flex-direction: column;
  align-items: center;
}

.hero-text-wrapper {
  text-align: center;
  padding: 10px 150px 50px;
}

.hero-bottom-wrapper h2 {
  margin: 0;
}

.hero-text-wrapper p {
  text-align: justify;
  margin-top: 30px;
}

.hero-text {
  padding: 40px 80px;
}

@media only screen and (max-width: 600px) {
  .hero-text {
    padding: 40px 30px;
  }
}

@media only screen and (max-width: 780px) {
  .video-overlay-text {
    padding: 0 30px;
  }

  .video-overlay-banner-container {
    width: 90%;
  }

  .video-overlay-banner {
    padding: 20px 60px;
}
}
