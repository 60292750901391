.main-padding {
  padding: 80px;
}

.main-margin {
  max-width: 1170px;
  margin: auto;
}

.negative-margin {
  margin-top: -180px;
}

.main-header-margin {
  max-width: 920px;
  margin: auto;
  padding: 0 30px;
}

.main-header {
  text-align: center;
  font-size: 2.5em;
  font-weight: 500;
  letter-spacing: -1px;
}

.block {
  display: block;
}

.t-center {
  text-align: center;
}

.t-uc {
  text-transform: uppercase;
}

.t-italic {
  font-style: italic;
}

.t-left {
  text-align: left;
}

.t-right {
  text-align: right;
}

.t-black {
  color: black;
}

.t-l-blue {
  color: #308db8;
}

.t-gold {
  color: #C39F5C
}

.c-pointer:hover {
  cursor: pointer;
}

.link-button {
  color: rgb(104, 104, 104);
  padding: 10px;
  border-radius: 5px;
  text-decoration: none;
  border: 1px solid rgb(104, 104, 104);
  width: 50%;
  margin: auto;
  text-align: center;
}

.link-button:hover {
  color: #308db8;
  padding: 10px;
  border-radius: 5px;
  text-decoration: none;
  border: 1px solid #308db8;
  width: 50%;
  margin: auto;
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  gap: 50px;
}

.card-grid-companies {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
  gap: 50px;
}

.grid-card {
  padding: 20px 50px;
  box-shadow: 0 10px 20px rgb(0 0 0 / 7%), 0 6px 6px rgb(0 0 0 / 3%);
  background: white;
  min-height: 300px;
  border-radius: 3px;
}

.grid-card h1 {
  text-align: center;
  font-weight: 600;
}

#mission,
#companies,
#bythenumbers,
#wireless-infrastructure,
#data-center-real-estate,
#digital-mobility {
  background: #ebebeb;
}

@media only screen and (max-width: 1160px) {
  .card-grid, .card-grid-companies {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media only screen and (max-width: 665px) {
  .negative-margin {
    margin-top: 0;
  }

  .main-padding {
    padding: 40px 30px;
  }
}

@media only screen and (max-width: 650px) {
  .vision-card {
    min-height: 200px;
  }

  #numbers-card-mobile {
    grid-template-rows: 1fr auto auto;
  }
}

@media only screen and (min-width: 700px) {
  .grid-card {
    width: 80%;
  }
}

@media only screen and (max-width: 550px) {
  .grid-card {
    padding: 20px;
  }
}
