/* By the numbers start */
.numbers-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
/* By the numbers end */

/* Companies start */
.companies-container {
  background: url("../../assets/images/ABOUTUS-COMPANIES.jpg") no-repeat 0 70%;
  background-size: cover;
  height: 40vh;
  color: white;
}

.companies-main-wrapper {
  display: grid;
  height: 100%;
}

.companies-card-inside {
  display: grid;
  height: 100%;
  align-content: space-evenly;
  justify-items: center;
}

.companies-bottom-wrapper {
  padding-top: 0;
  margin-top: -100px;
}
/* Companies end */

/* history start */
.overview-container {
  background: url("../../assets/images/BG-HISTORY.jpg") no-repeat 50% 25%;
  background-size: cover;
  height: 40vh;
  display: flex;
  color: white;
}

.overview-grid {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  justify-items: center;
  gap: 22px;
  margin: 80px 0;
}

.overview-grid-card {
  text-align: center;
}

.overview-grid-card p {
  margin-top: 0;
  font-size: 0.8em;
}
/* history end */

/* mission start */
.mission-container {
  background: url("../../assets/images/BG-COMMITMENT.jpg") no-repeat 50%;
  height: 40vh;
  color: black;
  display: grid;
}

.mission-main-wrapper {
  color: black;
}

.mission-grid-item {
  text-align: center;
}

.vert-line {
  border-right: 1px solid #dfdfdf;
  width: 1px;
  margin: 0;
}

.mission-bottom-container {
  padding: 0 80px 80px;
  margin-top: -100px;
  /* background-color: #f1f1f1; */
}
/* mission end */

/* numbers start */
.numbers-container {
  background: url("../../assets/images/BG-BYTHENUMBERS.jpg") no-repeat 50%;
  background-size: cover;
  height: 50vh;
  color: white;
}

.numbers-main-wrapper {
  display: flex;
  align-items: center;
  height: 100%;
}

.numbers-header {
  margin-top: 0;
}

.numbers-grid {
  display: grid;
  grid-template-columns: 1fr auto 1fr auto 1fr;
  gap: 50px;
}

.numbers-card {
  text-align: center;
  display: grid;
  grid-template-rows: 1fr auto 1fr;
  height: 100%;
}

.numbers-bottom-container {
  padding-top: 0;
  margin-top: -200px;
}

.numbers-card div h1 {
  font-weight: 500;
  font-size: 2em;
}

hr {
  width: 65%;
}
/* numbers end */

@media only screen and (max-width: 1130px) {
  .overview-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media only screen and (max-width: 780px) {
  .mission-container,
  .numbers-container {
    height: 100%;
    padding-bottom: 150px;
  }
  .numbers-bottom-container {
    margin-top: -100px;
  }
}

@media only screen and (max-width: 650px) {
  .overview-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .overview-container {
    background: linear-gradient(rgb(0 0 0 / 70%), rgba(0, 0, 0, 0.6)),
      url("../../assets/images/ABOUTUS-OVERVIEW.jpg") no-repeat 50% 25%;
    background-size: cover;
    height: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .mission-bottom-container {
    padding: 0 30px 80px;
  }
}

@media only screen and (max-width: 450px) {
  .companies-img {
    width: 100%;
  }
}

@media only screen and (min-width: 650px) {
  .mission-container {
    background-size: cover;
  }
}
