.team-container {
  background: url("../../assets/images/ABOUTUS-LEADERSHIP.jpg") no-repeat 50%
    15%;
  height: 40vh;
  display: flex;
  color: #294864;
  overflow: hidden;
}

.leadership-grid-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.card-img-container {
  overflow: hidden;
}

.leadership-img-wrapper {
  margin-bottom: 80px;
  /* display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    max-width: 500px; */
}

.l-left {
  margin-left: 80px;
}

.leadership-img-wrapper img,
.leadership-img-wrapper-main img {
  width: 100%;
}

.leadership-img-wrapper-main h4 {
  font-size: 1.2em;
  font-weight: 500;
}

.leadership-img-wrapper h4 {
  font-size: 1.1em;
  font-weight: 500;
}

/* .leadership-img-wrapper img:hover {
  cursor: pointer;
} */

.leadership-image-grid-wrapper {
  /* display: grid;
  grid-template-columns: 1fr 2fr; */
  /* grid-template-rows: repeat(3, auto); */
}

.leadership-img-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.leadership-img-grid-main {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 60px;
}

.bod-grid {
  grid-template-columns: repeat(3, 1fr);
}

.leadership-img-wrapper-main {
}

.leadership-leader-card-wrapper-main {
  box-shadow: 0 10px 20px rgb(0 0 0 / 7%), 0 6px 6px rgb(0 0 0 / 3%);
}

.leadership-leader-card-wrapper {
  box-shadow: 0 10px 20px rgb(0 0 0 / 7%), 0 6px 6px rgb(0 0 0 / 3%);
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.leadership-leader-card-wrapper h4,
.leadership-leader-card-wrapper h6,
.leadership-leader-card-wrapper-main h4,
.leadership-leader-card-wrapper-main h6 {
  margin: 0;
  text-transform: uppercase;
}

.leadership-leader-card-wrapper h4,
.leadership-leader-card-wrapper-main h4 {
  font-weight: 500;
  margin-bottom: 5px;
}

.leadership-leader-card-wrapper h6,
.leadership-leader-card-wrapper-main h6 {
  color: rgb(133, 133, 133);
  font-weight: 400;
}

.card-content-padding {
  padding: 14px;
}

/* BIO js */
.bio-container {
  /* margin-top: 30px; */
  /* box-shadow: 0 0px 35px rgba(0, 0, 0, 0.08), 0 10px 10px rgba(0, 0, 0, 0); */
  background: white;
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 5;
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
}

.bio-inner {
  width: 50%;
  background: white;
  padding: 20px;
  border-radius: 3px;
}

.bio-container img {
  max-width: 150px;
}

.bio-container h4 {
  margin-bottom: 0;
  text-transform: uppercase;
  font-weight: 500;
}

.bio-container h5 {
  margin-top: 4px;
  text-transform: uppercase;
  color: #8b8b8b;
  font-size: 0.8em;
  font-weight: 500;
}

.bio-container p {
  font-size: 0.7em;
}

.bio-img-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.bio-close-button:hover {
  cursor: pointer;
}

.bio-hash-img-link {
  height: 100%;
}

@media only screen and (max-width: 1300px) {
  .bod-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 1000px) {
  .team-container {
    height: 100%;
  }
  .leadership-img-grid-main {
    grid-template-columns: repeat(2, 1fr);
  }
  .bio-inner {
    width: 80%;
    overflow-y: scroll;
    max-height: 80%;
  }
}

@media only screen and (max-width: 900px) {
  .bod-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media only screen and (max-width: 880px) {
  .leadership-image-grid-wrapper {
    grid-template-columns: 1fr;
  }

  .l-left {
    margin-left: 0;
  }
}

@media only screen and (max-width: 730px) {
  .leadership-img-grid-main {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media only screen and (min-width: 650px) {
  .team-container {
    background-size: cover;
  }
}

@media only screen and (max-width: 500px) {
  .leadership-img-wrapper h4 {
    font-size: 0.9em;
  }
}

@media only screen and (max-width: 365px) {
  .card-content-padding {
    padding: 10px;
  }
  .leadership-img-wrapper h4 {
    font-size: 0.8em;
  }
}
