@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.main-padding {
  padding: 80px;
}

.main-margin {
  max-width: 1170px;
  margin: auto;
}

.negative-margin {
  margin-top: -180px;
}

.main-header-margin {
  max-width: 920px;
  margin: auto;
  padding: 0 30px;
}

.main-header {
  text-align: center;
  font-size: 2.5em;
  font-weight: 500;
  letter-spacing: -1px;
}

.block {
  display: block;
}

.t-center {
  text-align: center;
}

.t-uc {
  text-transform: uppercase;
}

.t-italic {
  font-style: italic;
}

.t-left {
  text-align: left;
}

.t-right {
  text-align: right;
}

.t-black {
  color: black;
}

.t-l-blue {
  color: #308db8;
}

.t-gold {
  color: #C39F5C
}

.c-pointer:hover {
  cursor: pointer;
}

.link-button {
  color: rgb(104, 104, 104);
  padding: 10px;
  border-radius: 5px;
  text-decoration: none;
  border: 1px solid rgb(104, 104, 104);
  width: 50%;
  margin: auto;
  text-align: center;
}

.link-button:hover {
  color: #308db8;
  padding: 10px;
  border-radius: 5px;
  text-decoration: none;
  border: 1px solid #308db8;
  width: 50%;
  margin: auto;
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  grid-gap: 50px;
  gap: 50px;
}

.card-grid-companies {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
  grid-gap: 50px;
  gap: 50px;
}

.grid-card {
  padding: 20px 50px;
  box-shadow: 0 10px 20px rgb(0 0 0 / 7%), 0 6px 6px rgb(0 0 0 / 3%);
  background: white;
  min-height: 300px;
  border-radius: 3px;
}

.grid-card h1 {
  text-align: center;
  font-weight: 600;
}

#mission,
#companies,
#bythenumbers,
#wireless-infrastructure,
#data-center-real-estate,
#digital-mobility {
  background: #ebebeb;
}

@media only screen and (max-width: 1160px) {
  .card-grid, .card-grid-companies {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media only screen and (max-width: 665px) {
  .negative-margin {
    margin-top: 0;
  }

  .main-padding {
    padding: 40px 30px;
  }
}

@media only screen and (max-width: 650px) {
  .vision-card {
    min-height: 200px;
  }

  #numbers-card-mobile {
    grid-template-rows: 1fr auto auto;
  }
}

@media only screen and (min-width: 700px) {
  .grid-card {
    width: 80%;
  }
}

@media only screen and (max-width: 550px) {
  .grid-card {
    padding: 20px;
  }
}

.nav-wrapper {
  background: rgb(255, 255, 255);
  color: white;
  position: fixed;
  z-index: 5;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  box-shadow: 0 8px 6px -6px #777;
}

.nav-icon {
  margin-right: 30px;
}

.nav-button {
  display: inline;
}

.nav-button:hover {
  cursor: pointer;
}

.nav-dropdown-main-show {
  position: fixed;
  z-index: 4;
  width: 275px;
  top: 80px;
  transition: 0.5s;
  height: 100%;
}

/* .nav-dropdown-main-show {
  position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    visibility: hidden;
    background-color: #fff;
    background-clip: padding-box;
    outline: 0;
    transition: transform .3s ease-in-out;

    top: 0;
    left: 0;
    width: 400px;
    border-right: 1px solid rgba(0,0,0,.2);

    transform: none;

    visibility: visible;
} */

.nav-dropdown-main-hide {
  position: absolute;
  top: 80px;
  width: 275px;
  display: flex;
  z-index: 4;
  transition: 0.5s;
  margin-left: -290px;
  height: 100%;
}

/* .nav-dropdown-main-hide {
  transition: transform .3s ease-in-out;
  display: none;
  visibility: hidden;
  position: absolute;
  top: -100%;
  left: -100%;
} */

.nav-dropdown {
  background-color: white;
  padding: 20px;
  height: 100%;
  position: fixed;
}

.nav-ul {
  list-style-type: none;
  padding: 0;
}

.nav-ul-show li {
  padding: 12px 6px;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
}

.nav-ul-hide li {
  padding: 12px 6px;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
}

.nav-ul li {
  padding: 6px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}

.nav-ul li:hover {
  background-color: rgb(223, 223, 223);
}

.nav-ul li a {
  text-decoration: none;
  color: rgb(44, 44, 44);
}
.nav-ul li a:hover {
  cursor: pointer;
}

.menu-grid {
}

.menu-grid-column h3,
.menu-contact-link {
  margin-bottom: 4px;
  color: rgb(44, 44, 44);
  font-weight: 500;
}

.menu-contact-link {
  text-decoration: none;
  font-size: 1.17em;
}

/* HAMBURGER */
/* "Hide" checkbox -- moves it off screen*/
#menu-btn {
  position: absolute;
  top: -100%;
  left: -100%;
}
/* Hide hamburger for bigger screens */
.menu-icon {
  visibility: hidden;
}
.menu-btn {
  display: none;
}
.menu-icon {
  grid-area: hamburger;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  padding: 30px 20px 30px 0;
  position: relative;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  visibility: visible;
}
.navicon {
  background: black;
  display: block;
  height: 2px;
  width: 18px;
  position: relative;
}
.navicon:before {
  top: 5px;
}
.navicon:after {
  top: -5px;
}
.navicon:before,
.navicon:after {
  background: black;
  display: block;
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  transition: all 0.2s ease-out;
}
.menu-btn:checked ~ .menu {
  max-height: 240px;
}
.menu-btn:checked ~ .menu-icon .navicon {
  background: transparent;
}
.menu-btn:checked ~ .menu-icon .navicon:before {
  transform: rotate(-45deg);
}
.menu-btn:checked ~ .menu-icon .navicon:after {
  transform: rotate(45deg);
}
.menu-btn:checked ~ .menu-icon .navicon:before,
.menu-btn:checked ~ .menu-icon .navicon:after {
  top: 0;
}

@media only screen and (max-width: 450px) {
  .nav-icon {
    margin-right: 25%;
  }
}

.contact-us-container {
  background: url(/static/media/CONTACTUS.be4f6462.jpg) no-repeat 50% 30%;
  background-size: cover;
  height: 30vh;
  color: black;
  display: grid;
}

.contact-us-content-wrapper p {
  max-width: 700px;
  margin: auto;
  text-align: center;
}

.contact-us-content-grid {
  display: flex;
  align-items: center;
  margin: 80px 0;
}

.contact-us-content-grid h3 {
  font-weight: 600;
}

.contact-links-section {
  width: 50%;
}

.contact-us-img-container {
  width: 50%;
}

.contact-us-img {
  width: 100%;
}

.contact-links-section a {
  color: #294864;
  text-decoration: none;
}

.contact-links-section a:hover {
  text-decoration: underline;
}

.contact-space-bot {
  margin-bottom: 50px;
}

.contact-space-top {
  margin-top: 50px;
}

@media only screen and (max-width: 845px) {
  .contact-us-content-grid {
    flex-flow: column;
    flex-direction: column-reverse;
  }
  .contact-links-section {
    width: 100%;
  }

  .contact-us-img-container {
    display: none;
  }
  .contact-us-img {
    width: 70%;
  }
}
@media only screen and (max-width: 500px) {
  .contact-us-img {
    width: 100%;
  }
}

/* overview start */
.wwd-overview-container {
  background: url(/static/media/WHATWEDO-OVERVIEW.07f9156d.jpg) no-repeat 50% 30%;
  height: 20vh;
  color: #294864;
  display: grid;
}

.wwd-overview-main-wrapper {
  margin-top: 100px;
}

.wwd-solutions-content-wrapper p:nth-of-type(2) {
  padding-bottom: 40px;
}
/* overview end */

/* solutions start */
.wwd-solutions-main-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  color: black;
  grid-gap: 100px;
  gap: 100px;
  align-items: center;
}

.wwd-solutions-container {
  padding-bottom: 120px;
}

.wwd-solutions-content-wrapper {
  margin-bottom: 75px;
}

.wwd-solutions-content-wrapper h1 {
  font-size: 2.5em;
  font-weight: 500;
}

.solutions-content-img {
  text-align: center;
  margin-left: 80px;
}
/* solutions end */

/* Investment Management Start */
.investment-management-card {
  margin-bottom: 65px;
}
/* Investment Management End */

/* Wholesale Distribution Start */
.wholesale-distribution-card {
  margin-bottom: 25px;
}

.wholesale-dist-top-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
}

.wholesale-dist-card-header {
  font-size: 2em;
  font-weight: 500;
  margin-bottom: 0;
}

.wholesale-dist-card-info {
  margin-top: 10px;
}

.wholesale-dist-bottom-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  justify-items: center;
  /* margin-top: 75px; */
}

.wholesale-dist-bottom-grid div {
  text-align: center;
}

.wwd-wholesale-content-wrapper {
  margin-bottom: 75px;
}

.wwd-wholesale-content-wrapper h1 {
  font-size: 2.5em;
  font-weight: 500;
}

.dist-learn-button-wrapper {
  margin-top: 50px;
}
/* Wholesale Distribution End */

/* Techops start */
.techops-bottom-grid {
  display: grid;
  grid-template-columns: 1fr 1px 1fr 1px 1fr;
  grid-gap: 25px;
  gap: 25px;
  justify-items: center;
  margin-top: 75px;
}

.techops-bottom-grid div {
  text-align: center;
}

.tech-hover-info {
  margin-top: 30px;
}

.vertical-line {
  border-right: 1px solid #dfdfdf;
  width: 1px;
}

.techops-grid-item {
  text-align: center;
}

.techops-grid-item img {
  display: inline;
}

.techops-grid-item h3 {
  font-weight: 500;
}

.test-grid {
  display: grid !important;
  grid-template-columns: 1fr 1px 1fr 1px 1fr;
  grid-gap: 25px;
  gap: 25px;
  justify-items: center;
}

.test-grid-mobile {
  display: grid !important;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 25px;
  gap: 25px;
  justify-items: center;
  margin-top: 70px;
}

.slick-next:before,
.slick-prev:before {
  font-size: 30px;
  line-height: 1;
  opacity: 0.75;
  color: #4b9cc1;
}

.slick-prev {
  left: -35px;
}
/* Techops end */

/* wireless start */
.wwd-wireless-container {
  background: url(/static/media/BG-WIRELESSINFRASTRUCTURE.8f593037.jpg) no-repeat 50%;
  background-size: cover;
  padding: 265px 80px 240px;
  color: white;
  display: grid;
}

.wwd-wireless-content-wrapper {
  margin-top: -100px;
}

.wwd-wireless-bottom-wrapper {
  padding-top: 0;
}
/* wireless end */

/* datacenter start */
.wwd-dc-container {
  background: url(/static/media/BG-DATACENTERREALESTATE.dfedae13.jpg) no-repeat 50%
    25%;
  background-size: cover;
  padding: 210px 80px 250px;
  color: white;
  display: grid;
}
.wwd-dc-content-wrapper {
  margin-top: -100px;
}

.wwd-dc-bottom-wrapper {
  padding-top: 0;
}
/* datacenter end */

/* digitalmobility start */
.wwd-dm-container {
  background: url(/static/media/WHATWEDO-5.b95e1415.jpg) no-repeat 50% 65%;
  background-size: cover;
  padding: 210px 80px 250px;
  color: white;
  display: grid;
}
.wwd-dm-content-wrapper {
  margin-top: -100px;
}

.wwd-dm-bottom-wrapper {
  padding-top: 0;
}
/* digitalmobility end */

.sector-focus-card-inside h1 {
  font-weight: 500;
  text-align: center;
}

.sector-focus-card-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
  grid-gap: 50px;
  gap: 50px;
}

.sector-focus-grid-card {
  padding: 20px 50px;
  box-shadow: 0 10px 20px rgb(0 0 0 / 7%), 0 6px 6px rgb(0 0 0 / 3%);
  background: white;
  min-height: 165px;
  border-radius: 3px;
}

/* video header start */
/* .video-header-container {
  position: relative;
} */

#header-video {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 40vh;
  object-fit: cover;
  object-position: center;
}

.header-video-text-overlay {
  min-height: 40vh;
  display: flex;
  justify-content: space-around;
  align-content: space-between;
  flex-direction: column;
}

.header-video-overlay-text {
  color: white;
  text-align: center;
  font-size: 2.5em;
  font-weight: 500;
  letter-spacing: -1px;
}
/* video header end */

.im-header {
  background: url(/static/media/BG-STRATCAPINVESTMENTMANAGEMENT.d6f00fb3.jpg) no-repeat 50% 25%;
  background-size: cover;
  height: 40vh;
  display: flex;
  color: white;
}

.wd-header {
  background: url(/static/media/BG-STRATCAPSECURITIES.84218760.jpg) no-repeat 50% 25%;
  background-size: cover;
  height: 40vh;
  display: flex;
  color: white;
}

/* All start */
.mobile-header-img {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 40vh;
  object-fit: cover;
  object-position: center;
}
/* All end */

@media only screen and (max-width: 1025px) {
  .wwd-solutions-main-wrapper {
    grid-template-columns: 1fr;
    justify-items: center;
    grid-gap: 0;
    gap: 0;
  }

  .wholesale-dist-top-grid {
    grid-gap: 30px;
    gap: 30px;
    grid-template-columns: repeat(4, 1fr);
    align-items: start;
  }

  .wwd-solutions-main-wrapper {
    display: flex;
    color: black;
    flex-flow: column;
    flex-direction: column-reverse;
  }
  .solutions-content-img {
    text-align: center;
    margin-left: 0;
  }
}
@media only screen and (max-width: 1000px) {
  .sector-focus-card-grid {
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
    grid-gap: 50px;
    gap: 50px;
  }
  .wholesale-dist-bottom-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 780px) {
  .techops-grid-item p {
    width: 75%;
    margin: auto;
  }
  .slick-next:before,
  .slick-prev:before {
    font-size: 20px;
  }
  .slick-prev {
    left: -26px;
  }
  .wholesale-dist-top-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 665px) {
  .solutions-content-img img {
    width: 100%;
  }
  .header-video-overlay-text {
    font-size: 2.2em;
    margin-top: 25%;
  }

  .wwd-solutions-content-wrapper h1, .wwd-wholesale-content-wrapper h1 {
    font-size: 2.2em;
  }

  .wholesale-dist-top-grid {
    align-items: start;
  }

  .wwd-wireless-container {
    background: url(/static/media/WHATWEDO-WIRELESS.98b31f4b.jpg) no-repeat 85%
      50%;
    background-size: cover;
  }
}

@media only screen and (min-width: 665px) {
  .solutions-content-img img {
    width: 100%;
  }

  .investment-management-card {
    padding: 40px 50px;
    box-shadow: 0 10px 20px rgb(0 0 0 / 7%), 0 6px 6px rgb(0 0 0 / 3%);
    background: white;
    border-radius: 3px;
    margin-bottom: 50px;
  }
  .wholesale-distribution-card {
    padding: 40px 50px;
    box-shadow: 0 10px 20px rgb(0 0 0 / 7%), 0 6px 6px rgb(0 0 0 / 3%);
    background: white;
    border-radius: 3px;
  }
}

@media only screen and (max-width: 550px) {
  .sector-focus-grid-card {
    padding: 20px;
  }
  .is-learn-button-wrapper {
    text-align: center;
  }
  .dist-learn-button-wrapper {
    text-align: center;
  }
  .wwd-solutions-content-wrapper {
    margin-top: -100px;
  }
  .wwd-wholesale-content-wrapper {
    margin-top: -100px;
    margin-bottom: 0;
  }
  .wholesale-dist-bottom-grid {
    grid-template-columns: repeat(2, 1fr);
    margin-top: 75px;
  }
  .wwd-solutions-main-wrapper {
    grid-gap: 100px;
    gap: 100px;
  }
}

@media only screen and (max-width: 540px) {
  /* .wwd-overview-container {
    height: 65vh;
  }
  .wireless-header {
    margin-top: 25%;
  } */
}

@media only screen and (max-width: 450px) {
  .dcre-header {
    padding: 0 10px;
  }
  .wholesale-dist-top-grid {
    grid-gap: 10px;
    gap: 10px;
  }
}

@media only screen and (min-width: 650px) {
  .wwd-overview-container {
    background-size: cover;
  }
}

/* @media only screen and (max-height: 700px) {
  .wwd-wireless-container {
    padding-bottom: 200px;
  }
} */

@media only screen and (max-width: 600px) {
  .wwd-dc-container,
  .wwd-dm-container {
    padding: 10px 30px 145px;
  }

  .wwd-wireless-container {
    padding: 75px 30px 125px;
  }
}

.login-wrapper {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  max-width: 300px;
  margin: auto;
  margin-top: 220px;
  padding: 40px;
  background: #c5c5c5;
  border-radius: 5px;
}

.logo-img-wrapper {
  max-width: 800px;
  margin-bottom: 20px;
}

.login-logo {
  width: 100%;
}

.submit-button {
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  background: #26a6db;
  border: none;
  border-radius: 3px;
  color: white;
}

.submit-button:hover {
  cursor: pointer;
}

input[type="password"] {
  padding: 6px;
  width: 94.6%;
}

.form-error {
  border: 1px solid #d44950;
  padding: 15px;
  background: #f8d7da;
  color: #d44950;
  margin-top: 10px;
  margin-bottom: 0;
  border-radius: 5px;
}

@media (max-width: 520px) {
  .login-wrapper {
    padding: 100px 20px;
  }
}

.cust-modal-hide {
  display: none;
  position: fixed;
  z-index: 6;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.cust-modal-show {
  display: block;
  position: fixed;
  z-index: 6;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

/* Modal Content/Box */
.cust-modal-content {
  background-color: #fefefe;
  margin: 8% auto;
  padding: 0 20px;
  border: 1px solid #888;
  border-radius: 3px;
  width: 80%;
  animation-name: animatetop;
  animation-duration: 0.7s;
}

/* The Close Button */
.cust-modal-close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  padding-top: 4px;
}

.cust-modal-close:hover,
.cust-modal-close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.modal-bottom-close {
  text-align: center;
  padding: 20px 0;
}

.modal-bottom-close button {
  width: 150px;
  height: 40px;
  border: none;
  font-size: 0.8em;
  color: gray;
  margin-top: 8px;
  text-transform: uppercase;
}

.modal-bottom-close button:hover {
  background-color: rgb(224, 224, 224);
  cursor: pointer;
}

/* Add Animation */
@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }

  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes animateleave {
  from {
    top: 0;
    opacity: 1;
  }

  to {
    top: -300px;
    opacity: 0;
  }
}

.footer-wrapper {
  background-color: black;
  /* background: url("../../assets/images/FOOTER.jpg") no-repeat center center; */
  background-size: cover;
  color: white;
  border-top: 12px solid #C39F5C;
}

.footer-disc-link {
  color: #308db8;
}

.hr-fw {
  width: 100%;
}

.footer-logo-wrapper {
  max-width: 200px;
}

.footer-logo-wrapper img {
  width: 100%;
}

.footer-top-section {
  display: grid;
  grid-template-columns: auto auto;
  border-bottom: 1px solid rgb(136, 136, 136);
  padding-bottom: 20px;
  justify-content: start;
}

.footer-middle-section {
  margin: 20px 0;
}

.footer-middle-section p {
  font-size: 0.7em;
}

.footer-bottom-section {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgb(136, 136, 136);
  padding-top: 12px;
  font-size: 0.7em;
}

.footer-links {
  padding: 0 30px;
}

.footer-links-a {
  display: block;
  text-decoration: none;
  color: white;
  text-transform: uppercase;
  font-size: 0.7em;
  margin: 9px 0;
}

.footer-links-header {
  text-transform: uppercase;
  text-decoration: none;
  color: white;
  margin: 15px 0 20px;
  font-weight: 500;
  font-size: 0.8em;
}

.footer-links-header:hover,
.footer-links-a:hover {
  text-decoration: underline;
}

/* PP and TOU stuff */
.text-modal-wrapper {
  color: rgb(20, 20, 20);
  padding: 40px;
}

@media only screen and (max-width: 650px) {
  .text-modal-wrapper {
    padding: 40px 0;
  }
}

/* By the numbers start */
.numbers-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
/* By the numbers end */

/* Companies start */
.companies-container {
  background: url(/static/media/ABOUTUS-COMPANIES.c3a7bb40.jpg) no-repeat 0 70%;
  background-size: cover;
  height: 40vh;
  color: white;
}

.companies-main-wrapper {
  display: grid;
  height: 100%;
}

.companies-card-inside {
  display: grid;
  height: 100%;
  align-content: space-evenly;
  justify-items: center;
}

.companies-bottom-wrapper {
  padding-top: 0;
  margin-top: -100px;
}
/* Companies end */

/* history start */
.overview-container {
  background: url(/static/media/BG-HISTORY.02707bb3.jpg) no-repeat 50% 25%;
  background-size: cover;
  height: 40vh;
  display: flex;
  color: white;
}

.overview-grid {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  justify-items: center;
  grid-gap: 22px;
  gap: 22px;
  margin: 80px 0;
}

.overview-grid-card {
  text-align: center;
}

.overview-grid-card p {
  margin-top: 0;
  font-size: 0.8em;
}
/* history end */

/* mission start */
.mission-container {
  background: url(/static/media/BG-COMMITMENT.6a300e7f.jpg) no-repeat 50%;
  height: 40vh;
  color: black;
  display: grid;
}

.mission-main-wrapper {
  color: black;
}

.mission-grid-item {
  text-align: center;
}

.vert-line {
  border-right: 1px solid #dfdfdf;
  width: 1px;
  margin: 0;
}

.mission-bottom-container {
  padding: 0 80px 80px;
  margin-top: -100px;
  /* background-color: #f1f1f1; */
}
/* mission end */

/* numbers start */
.numbers-container {
  background: url(/static/media/BG-BYTHENUMBERS.193de6ed.jpg) no-repeat 50%;
  background-size: cover;
  height: 50vh;
  color: white;
}

.numbers-main-wrapper {
  display: flex;
  align-items: center;
  height: 100%;
}

.numbers-header {
  margin-top: 0;
}

.numbers-grid {
  display: grid;
  grid-template-columns: 1fr auto 1fr auto 1fr;
  grid-gap: 50px;
  gap: 50px;
}

.numbers-card {
  text-align: center;
  display: grid;
  grid-template-rows: 1fr auto 1fr;
  height: 100%;
}

.numbers-bottom-container {
  padding-top: 0;
  margin-top: -200px;
}

.numbers-card div h1 {
  font-weight: 500;
  font-size: 2em;
}

hr {
  width: 65%;
}
/* numbers end */

@media only screen and (max-width: 1130px) {
  .overview-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media only screen and (max-width: 780px) {
  .mission-container,
  .numbers-container {
    height: 100%;
    padding-bottom: 150px;
  }
  .numbers-bottom-container {
    margin-top: -100px;
  }
}

@media only screen and (max-width: 650px) {
  .overview-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .overview-container {
    background: linear-gradient(rgb(0 0 0 / 70%), rgba(0, 0, 0, 0.6)),
      url(/static/media/ABOUTUS-OVERVIEW.bd4bcabf.jpg) no-repeat 50% 25%;
    background-size: cover;
    height: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .mission-bottom-container {
    padding: 0 30px 80px;
  }
}

@media only screen and (max-width: 450px) {
  .companies-img {
    width: 100%;
  }
}

@media only screen and (min-width: 650px) {
  .mission-container {
    background-size: cover;
  }
}

.team-container {
  background: url(/static/media/ABOUTUS-LEADERSHIP.6d576fc3.jpg) no-repeat 50%
    15%;
  height: 40vh;
  display: flex;
  color: #294864;
  overflow: hidden;
}

.leadership-grid-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  gap: 20px;
}

.card-img-container {
  overflow: hidden;
}

.leadership-img-wrapper {
  margin-bottom: 80px;
  /* display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    max-width: 500px; */
}

.l-left {
  margin-left: 80px;
}

.leadership-img-wrapper img,
.leadership-img-wrapper-main img {
  width: 100%;
}

.leadership-img-wrapper-main h4 {
  font-size: 1.2em;
  font-weight: 500;
}

.leadership-img-wrapper h4 {
  font-size: 1.1em;
  font-weight: 500;
}

/* .leadership-img-wrapper img:hover {
  cursor: pointer;
} */

.leadership-image-grid-wrapper {
  /* display: grid;
  grid-template-columns: 1fr 2fr; */
  /* grid-template-rows: repeat(3, auto); */
}

.leadership-img-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  gap: 20px;
}

.leadership-img-grid-main {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 60px;
}

.bod-grid {
  grid-template-columns: repeat(3, 1fr);
}

.leadership-img-wrapper-main {
}

.leadership-leader-card-wrapper-main {
  box-shadow: 0 10px 20px rgb(0 0 0 / 7%), 0 6px 6px rgb(0 0 0 / 3%);
}

.leadership-leader-card-wrapper {
  box-shadow: 0 10px 20px rgb(0 0 0 / 7%), 0 6px 6px rgb(0 0 0 / 3%);
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.leadership-leader-card-wrapper h4,
.leadership-leader-card-wrapper h6,
.leadership-leader-card-wrapper-main h4,
.leadership-leader-card-wrapper-main h6 {
  margin: 0;
  text-transform: uppercase;
}

.leadership-leader-card-wrapper h4,
.leadership-leader-card-wrapper-main h4 {
  font-weight: 500;
  margin-bottom: 5px;
}

.leadership-leader-card-wrapper h6,
.leadership-leader-card-wrapper-main h6 {
  color: rgb(133, 133, 133);
  font-weight: 400;
}

.card-content-padding {
  padding: 14px;
}

/* BIO js */
.bio-container {
  /* margin-top: 30px; */
  /* box-shadow: 0 0px 35px rgba(0, 0, 0, 0.08), 0 10px 10px rgba(0, 0, 0, 0); */
  background: white;
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 5;
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
}

.bio-inner {
  width: 50%;
  background: white;
  padding: 20px;
  border-radius: 3px;
}

.bio-container img {
  max-width: 150px;
}

.bio-container h4 {
  margin-bottom: 0;
  text-transform: uppercase;
  font-weight: 500;
}

.bio-container h5 {
  margin-top: 4px;
  text-transform: uppercase;
  color: #8b8b8b;
  font-size: 0.8em;
  font-weight: 500;
}

.bio-container p {
  font-size: 0.7em;
}

.bio-img-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.bio-close-button:hover {
  cursor: pointer;
}

.bio-hash-img-link {
  height: 100%;
}

@media only screen and (max-width: 1300px) {
  .bod-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 1000px) {
  .team-container {
    height: 100%;
  }
  .leadership-img-grid-main {
    grid-template-columns: repeat(2, 1fr);
  }
  .bio-inner {
    width: 80%;
    overflow-y: scroll;
    max-height: 80%;
  }
}

@media only screen and (max-width: 900px) {
  .bod-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media only screen and (max-width: 880px) {
  .leadership-image-grid-wrapper {
    grid-template-columns: 1fr;
  }

  .l-left {
    margin-left: 0;
  }
}

@media only screen and (max-width: 730px) {
  .leadership-img-grid-main {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media only screen and (min-width: 650px) {
  .team-container {
    background-size: cover;
  }
}

@media only screen and (max-width: 500px) {
  .leadership-img-wrapper h4 {
    font-size: 0.9em;
  }
}

@media only screen and (max-width: 365px) {
  .card-content-padding {
    padding: 10px;
  }
  .leadership-img-wrapper h4 {
    font-size: 0.8em;
  }
}

.hero-main-header {
  font-size: 2.5em;
}

.hero-container {
  position: relative;
}

.hero_content_container {
  /* padding: 0 40px; */
}

.video-overlay-banner {
  background: #000000;
  padding: 50px 160px;
  margin-top: 50px
}

.hmcLearnMoreButton {
  background-color: #c39f5c;
  border: none;
  padding: 8px 15px;
  color: white;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  letter-spacing: 1px;
  border-radius: 20px;
}

.hmcLearnMoreButton:hover,
.hmcBannerCloseButton:hover {
  cursor: pointer;
}

.hmcBannerCloseButton:hover {
  color: #fff;
  background-color: #970b29;
  border-color: #8e0a26;
  olor: #b20d30;
}

.hmcLearnMoreButton:hover {
  cursor: pointer;
}

.hmcBannerCloseButton {
  color: #fff;
  background-color: #b20d30;
  border-color: #b20d30;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  border: 1px solid rgba(0, 0, 0, 0);
}

#hero-video {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 85vh;
  object-fit: cover;
  object-position: center;
}

.hero-text-overlay {
  min-height: 85vh;
  display: flex;
  justify-content: center;
  color: white;
  align-content: space-between;
  flex-direction: column;
  align-items: center;
}

.hero-text-wrapper {
  text-align: center;
  padding: 10px 150px 50px;
}

.hero-bottom-wrapper h2 {
  margin: 0;
}

.hero-text-wrapper p {
  text-align: justify;
  margin-top: 30px;
}

.hero-text {
  padding: 40px 80px;
}

@media only screen and (max-width: 600px) {
  .hero-text {
    padding: 40px 30px;
  }
}

@media only screen and (max-width: 780px) {
  .video-overlay-text {
    padding: 0 30px;
  }

  .video-overlay-banner-container {
    width: 90%;
  }

  .video-overlay-banner {
    padding: 20px 60px;
}
}

