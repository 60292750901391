/* overview start */
.wwd-overview-container {
  background: url("../../assets/images/WHATWEDO-OVERVIEW.jpg") no-repeat 50% 30%;
  height: 20vh;
  color: #294864;
  display: grid;
}

.wwd-overview-main-wrapper {
  margin-top: 100px;
}

.wwd-solutions-content-wrapper p:nth-of-type(2) {
  padding-bottom: 40px;
}
/* overview end */

/* solutions start */
.wwd-solutions-main-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  color: black;
  gap: 100px;
  align-items: center;
}

.wwd-solutions-container {
  padding-bottom: 120px;
}

.wwd-solutions-content-wrapper {
  margin-bottom: 75px;
}

.wwd-solutions-content-wrapper h1 {
  font-size: 2.5em;
  font-weight: 500;
}

.solutions-content-img {
  text-align: center;
  margin-left: 80px;
}
/* solutions end */

/* Investment Management Start */
.investment-management-card {
  margin-bottom: 65px;
}
/* Investment Management End */

/* Wholesale Distribution Start */
.wholesale-distribution-card {
  margin-bottom: 25px;
}

.wholesale-dist-top-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
}

.wholesale-dist-card-header {
  font-size: 2em;
  font-weight: 500;
  margin-bottom: 0;
}

.wholesale-dist-card-info {
  margin-top: 10px;
}

.wholesale-dist-bottom-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  justify-items: center;
  /* margin-top: 75px; */
}

.wholesale-dist-bottom-grid div {
  text-align: center;
}

.wwd-wholesale-content-wrapper {
  margin-bottom: 75px;
}

.wwd-wholesale-content-wrapper h1 {
  font-size: 2.5em;
  font-weight: 500;
}

.dist-learn-button-wrapper {
  margin-top: 50px;
}
/* Wholesale Distribution End */

/* Techops start */
.techops-bottom-grid {
  display: grid;
  grid-template-columns: 1fr 1px 1fr 1px 1fr;
  gap: 25px;
  justify-items: center;
  margin-top: 75px;
}

.techops-bottom-grid div {
  text-align: center;
}

.tech-hover-info {
  margin-top: 30px;
}

.vertical-line {
  border-right: 1px solid #dfdfdf;
  width: 1px;
}

.techops-grid-item {
  text-align: center;
}

.techops-grid-item img {
  display: inline;
}

.techops-grid-item h3 {
  font-weight: 500;
}

.test-grid {
  display: grid !important;
  grid-template-columns: 1fr 1px 1fr 1px 1fr;
  gap: 25px;
  justify-items: center;
}

.test-grid-mobile {
  display: grid !important;
  grid-template-columns: repeat(3, 1fr);
  gap: 25px;
  justify-items: center;
  margin-top: 70px;
}

.slick-next:before,
.slick-prev:before {
  font-size: 30px;
  line-height: 1;
  opacity: 0.75;
  color: #4b9cc1;
}

.slick-prev {
  left: -35px;
}
/* Techops end */

/* wireless start */
.wwd-wireless-container {
  background: url("../../assets/images/BG-WIRELESSINFRASTRUCTURE.jpg") no-repeat 50%;
  background-size: cover;
  padding: 265px 80px 240px;
  color: white;
  display: grid;
}

.wwd-wireless-content-wrapper {
  margin-top: -100px;
}

.wwd-wireless-bottom-wrapper {
  padding-top: 0;
}
/* wireless end */

/* datacenter start */
.wwd-dc-container {
  background: url("../../assets/images/BG-DATACENTERREALESTATE.jpg") no-repeat 50%
    25%;
  background-size: cover;
  padding: 210px 80px 250px;
  color: white;
  display: grid;
}
.wwd-dc-content-wrapper {
  margin-top: -100px;
}

.wwd-dc-bottom-wrapper {
  padding-top: 0;
}
/* datacenter end */

/* digitalmobility start */
.wwd-dm-container {
  background: url("../../assets/images/WHATWEDO-5.jpg") no-repeat 50% 65%;
  background-size: cover;
  padding: 210px 80px 250px;
  color: white;
  display: grid;
}
.wwd-dm-content-wrapper {
  margin-top: -100px;
}

.wwd-dm-bottom-wrapper {
  padding-top: 0;
}
/* digitalmobility end */

.sector-focus-card-inside h1 {
  font-weight: 500;
  text-align: center;
}

.sector-focus-card-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
  gap: 50px;
}

.sector-focus-grid-card {
  padding: 20px 50px;
  box-shadow: 0 10px 20px rgb(0 0 0 / 7%), 0 6px 6px rgb(0 0 0 / 3%);
  background: white;
  min-height: 165px;
  border-radius: 3px;
}

/* video header start */
/* .video-header-container {
  position: relative;
} */

#header-video {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 40vh;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}

.header-video-text-overlay {
  min-height: 40vh;
  display: flex;
  justify-content: space-around;
  align-content: space-between;
  flex-direction: column;
}

.header-video-overlay-text {
  color: white;
  text-align: center;
  font-size: 2.5em;
  font-weight: 500;
  letter-spacing: -1px;
}
/* video header end */

.im-header {
  background: url("../../assets/images/BG-STRATCAPINVESTMENTMANAGEMENT.jpg") no-repeat 50% 25%;
  background-size: cover;
  height: 40vh;
  display: flex;
  color: white;
}

.wd-header {
  background: url("../../assets/images/BG-STRATCAPSECURITIES.jpg") no-repeat 50% 25%;
  background-size: cover;
  height: 40vh;
  display: flex;
  color: white;
}

/* All start */
.mobile-header-img {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 40vh;
  object-fit: cover;
  object-position: center;
}
/* All end */

@media only screen and (max-width: 1025px) {
  .wwd-solutions-main-wrapper {
    grid-template-columns: 1fr;
    justify-items: center;
    gap: 0;
  }

  .wholesale-dist-top-grid {
    gap: 30px;
    grid-template-columns: repeat(4, 1fr);
    align-items: start;
  }

  .wwd-solutions-main-wrapper {
    display: flex;
    color: black;
    flex-flow: column;
    flex-direction: column-reverse;
  }
  .solutions-content-img {
    text-align: center;
    margin-left: 0;
  }
}
@media only screen and (max-width: 1000px) {
  .sector-focus-card-grid {
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
    gap: 50px;
  }
  .wholesale-dist-bottom-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 780px) {
  .techops-grid-item p {
    width: 75%;
    margin: auto;
  }
  .slick-next:before,
  .slick-prev:before {
    font-size: 20px;
  }
  .slick-prev {
    left: -26px;
  }
  .wholesale-dist-top-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 665px) {
  .solutions-content-img img {
    width: 100%;
  }
  .header-video-overlay-text {
    font-size: 2.2em;
    margin-top: 25%;
  }

  .wwd-solutions-content-wrapper h1, .wwd-wholesale-content-wrapper h1 {
    font-size: 2.2em;
  }

  .wholesale-dist-top-grid {
    align-items: start;
  }

  .wwd-wireless-container {
    background: url("../../assets/images/WHATWEDO-WIRELESS.jpg") no-repeat 85%
      50%;
    background-size: cover;
  }
}

@media only screen and (min-width: 665px) {
  .solutions-content-img img {
    width: 100%;
  }

  .investment-management-card {
    padding: 40px 50px;
    box-shadow: 0 10px 20px rgb(0 0 0 / 7%), 0 6px 6px rgb(0 0 0 / 3%);
    background: white;
    border-radius: 3px;
    margin-bottom: 50px;
  }
  .wholesale-distribution-card {
    padding: 40px 50px;
    box-shadow: 0 10px 20px rgb(0 0 0 / 7%), 0 6px 6px rgb(0 0 0 / 3%);
    background: white;
    border-radius: 3px;
  }
}

@media only screen and (max-width: 550px) {
  .sector-focus-grid-card {
    padding: 20px;
  }
  .is-learn-button-wrapper {
    text-align: center;
  }
  .dist-learn-button-wrapper {
    text-align: center;
  }
  .wwd-solutions-content-wrapper {
    margin-top: -100px;
  }
  .wwd-wholesale-content-wrapper {
    margin-top: -100px;
    margin-bottom: 0;
  }
  .wholesale-dist-bottom-grid {
    grid-template-columns: repeat(2, 1fr);
    margin-top: 75px;
  }
  .wwd-solutions-main-wrapper {
    gap: 100px;
  }
}

@media only screen and (max-width: 540px) {
  /* .wwd-overview-container {
    height: 65vh;
  }
  .wireless-header {
    margin-top: 25%;
  } */
}

@media only screen and (max-width: 450px) {
  .dcre-header {
    padding: 0 10px;
  }
  .wholesale-dist-top-grid {
    gap: 10px;
  }
}

@media only screen and (min-width: 650px) {
  .wwd-overview-container {
    background-size: cover;
  }
}

/* @media only screen and (max-height: 700px) {
  .wwd-wireless-container {
    padding-bottom: 200px;
  }
} */

@media only screen and (max-width: 600px) {
  .wwd-dc-container,
  .wwd-dm-container {
    padding: 10px 30px 145px;
  }

  .wwd-wireless-container {
    padding: 75px 30px 125px;
  }
}
