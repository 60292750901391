.contact-us-container {
  background: url("../../assets/images/CONTACTUS.jpg") no-repeat 50% 30%;
  background-size: cover;
  height: 30vh;
  color: black;
  display: grid;
}

.contact-us-content-wrapper p {
  max-width: 700px;
  margin: auto;
  text-align: center;
}

.contact-us-content-grid {
  display: flex;
  align-items: center;
  margin: 80px 0;
}

.contact-us-content-grid h3 {
  font-weight: 600;
}

.contact-links-section {
  width: 50%;
}

.contact-us-img-container {
  width: 50%;
}

.contact-us-img {
  width: 100%;
}

.contact-links-section a {
  color: #294864;
  text-decoration: none;
}

.contact-links-section a:hover {
  text-decoration: underline;
}

.contact-space-bot {
  margin-bottom: 50px;
}

.contact-space-top {
  margin-top: 50px;
}

@media only screen and (max-width: 845px) {
  .contact-us-content-grid {
    flex-flow: column;
    flex-direction: column-reverse;
  }
  .contact-links-section {
    width: 100%;
  }

  .contact-us-img-container {
    display: none;
  }
  .contact-us-img {
    width: 70%;
  }
}
@media only screen and (max-width: 500px) {
  .contact-us-img {
    width: 100%;
  }
}
